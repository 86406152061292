@media (max-width: 768px) {
  .MuiSlider-thumb {
    height: 13px !important;
    width: 13px !important;
  }
}

.MuiSlider-thumb {
    border: 1px solid #131D2E;
    color: #DECDAD;
    overflow-y: visible;
    overflow-x: visible;
  }
  .MuiSlider-track {
    color: #131D2E;
    border: none;
    width: 80%;
    height: 1px
  }
  
  .MuiSlider-rail {
    color: #131D2E;
    width: 80%;
    overflow-x: visible;
    height: 1px !important;
  }

  .MuiSlider-root {
    overflow: visible;
    height: 1px !important;
    
  }

  .index-module_Container__zFpuF {
    object-fit: cover;
  }

a{
  color: black
}