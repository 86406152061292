.footer_input{
    display :flex;
    gap: 10px;
    margin-top: 20px;
}

.footer_submit{
    display :flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
}

.socialmediaicons{
    margin-top: 10px;
    display: flex;
    gap:21px;
}

.get_in_touch{
    width:328px;
}

.social_join{
    margin-top: 50px;
    width:328px;
}

@media (max-width: 772px) {
    .social_join{
      margin-top: 30px;
      text-align: center;
    }
    .socialmediaicons{
        justify-content: center;
    }
}
@media (min-width:900px) {
    .get_in_touch {
        margin-top: 50px;
    }
}

