video {
  width: 100% !important;
  height: 100vh !important;
  object-fit: cover;
}

@media only screen and (min-width: 1900px) {
  .MuiTypography-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #000000;
  }
}
@media only screen and (min-width: 1300px) and (max-width:1900px) {
  .MuiTypography-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #000000;
  }
}
@media only screen and (min-width: 900px) and (max-width:1300px) {
  .MuiTypography-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #000000;
  }
}
@media only screen and (max-width: 900px) {
  .MuiTypography-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #000000;
  }
}

@media only screen and (min-width: 1900px) {
  .css-1gqyz35-MuiTypography-root {
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 127% !important;
    letter-spacing: 0.03em !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }
}
@media only screen and (min-width: 1300px) and (max-width:1900px) {
  .css-1gqyz35-MuiTypography-root {
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 127% !important;
    letter-spacing: 0.03em !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }
}
@media only screen and (min-width: 900px) and (max-width:1300px) {
  .css-1gqyz35-MuiTypography-root {
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 127% !important;
    letter-spacing: 0.03em !important;
    color: rgba(0, 0, 0, 0.6)!important;
  }
}
@media (max-width: 900px) {
  .css-1gqyz35-MuiTypography-root {
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 127% !important;
    letter-spacing: 0.03em !important;
    color:rgba(0, 0, 0, 0.6) !important ;
  }
}
