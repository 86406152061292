@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: Didot;
  src: url(assets/fonts/Didot-Regular.ttf);
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
}
body{
padding: 0px !important;
}
textarea:focus, input:focus{
  outline: none;
}
input:focus::placeholder {
  color: transparent;
}


ul{
  margin-bottom: 0px;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

h1::-webkit-scrollbar{
  display: none;
}

a {
  cursor: pointer;
  text-decoration: none;
}

input[type="checkbox" i] {
  accent-color:#004876;
  margin-right: 10px;
  width:14px;
  height:14px;
}

#waveform {
  overflow: hidden !important;
}

input[type="file"]::-webkit-file-upload-button {
  border: none;
  padding: 10px 40px;
  background: #12284c;
  color: #fff;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
  border-radius: 20px;
}



h1,h2,h3,h4,h5,h6 {
  cursor: default;
}

.video-player {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.video-player::-webkit-scrollbar {
  display: none;
}

.html5-video-container::-webkit-scrollbar {
  overflow: hidden!important;
  display: none;
}

.cmihnX {
  padding: 20px 20px !important;
}

#video-container {
  margin-top: 30px;
  position: relative;
  display: flex;
  width: 100vw;
  height: 90vh;
  overflow: hidden;
 }

 .react-datepicker-ignore-onclickoutside {
    display: none !important;
 }

 .calender-custom-header {
  display: flex;
justify-content: space-between; 
padding: 0px 20px;
}

.slick-slider {
  top:-60px;
  overflow: visible;
}

.slick-slide {
  /* margin-right: 5px; */
 }
.slick-dots {
  position: relative !important;
  bottom: 0px !important;
}
 .slick-dots li {
  height: 100px !important;
  margin: 0px 0px !important;
 }

 .slick-dots li button:before {
  content: '—' !important;
  font-size: 50px !important;
 }



.sc-fJNrnh.dHNJYf {
  overflow-x:visible !important;
}
.sc-bnVMcY.bOFuOP {
  margin-bottom: 20px !important;
}


 .react-datepicker {
  width: 100% !important;
 }
.slick-arrow {
  display: none !important;
}
 .react-datepicker__month-container {
  width: 100% !important;
 }