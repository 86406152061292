@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: transparent !important;
    overflow: hidden;
    border: 1px solid #000000;
    padding: 25px 20px;
    border-radius: 50%;
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: transparent !important;
    overflow: hidden;
    border: 1px solid #000000;
    padding: 25px 20px;
    border-radius: 50%;
    font-size: 15px;
  }
}

@media only screen and (min-width: 1900px) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: transparent !important;
    overflow: hidden;
    border: 1px solid #000000;
    padding: 25px 20px;
    border-radius: 50%;
    font-size: 18px;
  }
}
@media only screen and (min-width: 1300px) and (max-width:1900px) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: transparent !important;
    overflow: hidden;
    border: 1px solid #000000;
    padding: 25px 20px;
    border-radius: 50%;
    font-size: 18px;
  }
}
@media only screen and (min-width: 900px) and (max-width:1300px) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: transparent !important;
    overflow: hidden;
    border: 1px solid #000000;
    padding: 25px 20px;
    border-radius: 50%;
    font-size: 15px;
  }
}
@media only screen and (max-width: 900px) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: transparent !important;
    overflow: hidden;
    border: 1px solid #000000;
    padding: 25px 20px;
    border-radius: 50%;
    font-size: 13px;
  }
}

@media only screen and (min-width: 1900px) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
    font-size: 18px;
    background-color: transparent;
  }
}
@media only screen and (min-width: 1300px) and (max-width:1900px) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
    font-size: 18px;
    background-color: transparent;
  }
}
@media only screen and (min-width: 900px) and (max-width:1300px) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
    font-size: 15px;
    background-color: transparent;
  }
}
@media only screen and (max-width: 900px) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
    font-size: 13px;
    background-color: transparent;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
    font-size: 15px;
    background-color: transparent;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
    font-size: 15px;
    background-color: transparent;
  }
}
